export const getManagementData = (t: (key: string) => string) => [
  {
    name: t('management.yandovsky.name'),
    position: t('management.yandovsky.position'),
    phones: [
      {
        number: '+375(29) 167-13-13',
        description: t('management.phones.mobile'),
      },
      { number: '+375(17) 270-13-13' },
    ],
    appointment: t('management.yandovsky.appointment'),
  },
  {
    name: t('management.kupriyanov.name'),
    position: t('management.kupriyanov.position'),
    phones: [{ number: '+375(25) 718-05-02' }],
    appointment: t('management.kupriyanov.appointment'),
  },
  {
    name: t('management.artyukh.name'),
    position: t('management.artyukh.position'),
    phones: [{ number: '+375(25) 718-06-54' }],
    appointment: t('management.artyukh.appointment'),
  },
  // {
  //   name: t('management.kondrukevich.name'),
  //   position: t('management.kondrukevich.position'),
  //   phones: [{ number: '+375(25) 718-01-06' }],
  //   appointment: t('management.kondrukevich.appointment'),
  // },
  {
    name: t('management.kondrashov.name'),
    position: t('management.kondrashov.position'),
    phones: [{ number: '+375(25) 914-94-22' }],
    appointment: t('management.kondrashov.appointment'),
  },
  {
    name: t('management.klimovich.name'),
    position: t('management.klimovich.position'),
    phones: [{ number: '+375(25) 718-06-23' }],
    appointment: t('management.klimovich.appointment'),
  },
  // {
  //   name: t('management.samal.name'),
  //   position: t('management.samal.position'),
  //   phones: [{ number: '+375(25) 718-02-81' }],
  //   appointment: t('management.samal.appointment'),
  // },
  {
    name: t('management.paniznik.name'),
    position: t('management.paniznik.position'),
    phones: [{ number: '+375(29) 622-21-49' }],
    appointment: t('management.paniznik.appointment'),
  },
  // {
  //   name: t('management.zhuravel.name'),
  //   position: t('management.zhuravel.position'),
  //   phones: [{ number: '+375(25) 718-05-38' }],
  //   appointment: t('management.zhuravel.appointment'),
  // },
  {
    name: t('management.andrienko.name'),
    position: t('management.andrienko.position'),
    phones: [{ number: '+375(25) 718-05-16' }],
    appointment: t('management.andrienko.appointment'),
  },
  {
    name: t('management.nesterenko.name'),
    position: t('management.nesterenko.position'),
    phones: [{ number: '+375(25) 743-12-95' }],
    appointment: t('management.nesterenko.appointment'),
  },
  {
    name: t('management.kondrashova.name'),
    position: t('management.kondrashova.position'),
    phones: [{ number: '+375(25) 718-02-88' }],
    appointment: t('management.kondrashova.appointment'),
  },
  {
    name: t('management.zhukovsky.name'),
    position: t('management.zhukovsky.position'),
    phones: [{ number: '+375(25) 718-05-00' }],
    appointment: t('management.zhukovsky.appointment'),
  },
  {
    name: t('management.zherdeva.name'),
    position: t('management.zherdeva.position'),
    phones: [
      {
        number: '+375 (17) 677-04-02',
        description: t('management.phones.office'),
      },
      {
        number: '+375 (25) 718-05-35',
        description: t('management.phones.mobile'),
      },
    ],
    appointment: t('management.zherdeva.appointment'),
  },
];
