import React from 'react';
import style from './FooterContacts.module.scss';
import { useTranslation } from 'react-i18next';

const FooterContacts = () => {
  const { t } = useTranslation();

  return (
    <div className={style.footerContacts}>
      <h4 className={style.title}>{t('Footer.contactPhone')}</h4>
      <div className={style.content}>
        <ul className={style.contactList}>
          <li>
            <i className={style.iconPhone}></i>
            <a href="tel:+375176770402">+375 (17) 677-04-02</a>
          </li>
          <li>
            <i className={style.iconPhone}></i>
            <a href="tel:+375257180535">+375 (25) 718-05-35</a>
          </li>
          <li>
            <i className={style.iconPhone}></i>
            <a href="tel:+375257180592">+375 (25) 718-05-92</a>
          </li>
        </ul>

        <div className={style.workingHours}>
          <h4 className={style.title}>{t('Footer.workingHours')}</h4>
          <ul className={style.contactList}>
            <li>{t('Footer.hours.monFri')}</li>
            <li>{t('Footer.hours.break')}</li>
            <li>{t('Footer.hours.satSun')}</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default FooterContacts;
