import React from 'react';
import style from './FooterEmail.module.scss';
import { useTranslation } from 'react-i18next';
// import image_1 from 'src/assets/images/2024_year_of_quality-removebg-preview (1).jpg';
import image_1 from 'src/assets/images/god-blagoustrojstva-pravka-1.jpg';

const FooterEmail = () => {
  const { t } = useTranslation();

  return (
    <div className={style.footerEmail}>
      <h4 className={style.title}>{t('Footer.email')}</h4>
      <div className={style.content}>
        <ul className={style.contactList}>
          <li>
            <i className={style.iconEmail}></i>
            <a href="mailto:rupzmk@yandex.by">rupzmk@yandex.by</a>
          </li>
          <li>
            <i className={style.iconEmail}></i>
            <a href="mailto:rupzmk@gmail.com">rupzmk@gmail.com</a>
          </li>
        </ul>
        <div className={style.qualityImage}>
          <img 
            src={image_1} 
            alt="2025 Год благоустройства" 
          />
        </div>
      </div>
    </div>
  );
};

export default FooterEmail;
